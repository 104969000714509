'use client'

import dynamic from 'next/dynamic'
import { Fragment } from 'react'

import { Loading } from '@/components/atoms/icons'

const HeroCarousel = dynamic(() => import('@/components/molecules/carousel/hero-carousel').then((mod) => mod.HeroCarousel), {
  loading: () => (
    <div className="flex items-center bg-primary-blue justify-center w-full h-[40vh] lg:h-[90vh]">
      <Loading className="w-10 h-10 text-white" />
    </div>
  )
})

interface LandingHeroProps {
  data: Slider
}

export const LandingHero = ({ data }: LandingHeroProps) => {
  return (
    <Fragment>
      <h1 className="sr-only">Halaman Home</h1>
      <HeroCarousel data={data} />
    </Fragment>
  )
}
