import { cn } from '@/lib/utils'

interface IconsProps {
  className?: string
}

export const Loading = ({ className }: IconsProps) => (
  <div className="flex items-center justify-center w-full text-dark">
    <svg
      className={cn('w-5 h-5 animate-spin', className)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
)

export const QuoteIcon = ({ className }: IconsProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="23" fill="none" viewBox="0 0 24 23">
    <path
      fill="#FFCF32"
      d="M14.4 22.58v-8.064c0-3.712.8-6.784 2.4-9.216 1.664-2.432 4.064-4.032 7.2-4.8v3.36c-1.92.512-3.296 1.472-4.128 2.88-.832 1.344-1.312 3.104-1.44 5.28h3.648v10.56H14.4zm-14.4 0v-8.064C0 10.804.8 7.732 2.4 5.3 4.064 2.868 6.464 1.268 9.6.5v3.36c-1.92.512-3.296 1.472-4.128 2.88-.832 1.344-1.312 3.104-1.44 5.28H7.68v10.56H0z"
    ></path>
  </svg>
)

export const Quote2Icon = ({ className }: IconsProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="59" height="39" fill="none" viewBox="0 0 59 39">
    <path fill="#FFCF2F" d="M26 0H0v27h10v12l11.5-12H26V0zM59 0H33v27h10v12l11.5-12H59V0z"></path>
  </svg>
)

export const StarIcon = ({ className }: IconsProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1469_3578)">
      <path
        fill="#fff"
        d="M7.992 1.333A6.663 6.663 0 001.332 8c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0014.665 8a6.67 6.67 0 00-6.673-6.667zM10.819 12l-2.82-1.7-2.82 1.7.746-3.207L3.44 6.64l3.28-.28 1.28-3.027 1.28 3.02 3.28.28-2.487 2.154.747 3.213z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1469_3578">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export const AwesomeIcon = ({ className }: IconsProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1469_3603)">
      <path
        fill="#fff"
        d="M12.668 6l.833-1.833 1.834-.834L13.5 2.5 12.668.667 11.835 2.5 10 3.333l1.834.834L12.668 6zm-5 .333L6.001 2.667 4.335 6.333.668 8l3.667 1.667L6 13.333l1.667-3.666L11.335 8 7.668 6.333zm5 3.667l-.833 1.833-1.834.834 1.834.833.833 1.833.833-1.833 1.834-.833-1.834-.834L12.668 10z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1469_3603">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export const FireIcon = ({ className }: IconsProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1469_3226)">
      <path
        fill="#fff"
        d="M12.988 8.233c-1.047-2.72-4.773-2.866-3.873-6.82a.336.336 0 00-.5-.366c-2.42 1.426-4.16 4.286-2.7 8.033.12.307-.24.593-.5.393C4.208 8.56 4.08 7.247 4.188 6.307c.04-.347-.413-.514-.607-.227-.453.693-.913 1.813-.913 3.5.253 3.733 3.407 4.88 4.54 5.027 1.62.206 3.373-.094 4.633-1.247 1.387-1.287 1.894-3.34 1.147-5.127zm-6.187 3.354c.96-.234 1.454-.927 1.587-1.54.22-.954-.64-1.887-.06-3.394.22 1.247 2.18 2.027 2.18 3.387.053 1.687-1.773 3.133-3.707 1.547z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1469_3226">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export const EconomicalIcon = ({ className }: IconsProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1522_6895)">
      <path
        fill="#fff"
        d="M2.165 1.834L13.5 13.167l-.834.834-1.333-1.334h-8c-.733 0-1.333-.6-1.333-1.333V4.667c0-.366.153-.7.393-.94l-1.06-1.06.833-.833zm12.5 6.167l-2.913-4.107a1.33 1.33 0 00-1.087-.56H5.332l7.333 7.333 2-2.666z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1522_6895">
        <path fill="#fff" d="M0 0H16V16H0z"></path>
      </clipPath>
    </defs>
  </svg>
)

export const ChevronRoundedIcon = ({ className }: IconsProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
    <path
      fill="#DBEAFE"
      d="M.5 16C.5 7.44 7.44.5 16 .5 24.56.5 31.5 7.44 31.5 16c0 8.56-6.94 15.5-15.5 15.5C7.44 31.5.5 24.56.5 16z"
    ></path>
    <path
      stroke="#D1D5DB"
      d="M.5 16C.5 7.44 7.44.5 16 .5 24.56.5 31.5 7.44 31.5 16c0 8.56-6.94 15.5-15.5 15.5C7.44 31.5.5 24.56.5 16z"
    ></path>
    <g clipPath="url(#clip0_1594_593)">
      <g clipPath="url(#clip1_1594_593)">
        <path fill="#036" d="M20.59 12.59L16 17.17l-4.59-4.58L10 14l6 6 6-6-1.41-1.41z"></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1594_593">
        <path fill="#fff" d="M0 0H24V24H0z" transform="translate(4 4)"></path>
      </clipPath>
      <clipPath id="clip1_1594_593">
        <path fill="#fff" d="M0 0H24V24H0z" transform="translate(4 4)"></path>
      </clipPath>
    </defs>
  </svg>
)
