'use client'

import dynamic from 'next/dynamic'
import { Fragment } from 'react'

import { WhatsappSticky } from '@/components/molecules/whatsapp-sticky'

const Navbar = dynamic(() => import('@/components/organisms/navbar').then((mod) => mod.Navbar), {
  ssr: false
})

const Footer = dynamic(() => import('@/components/organisms/footer').then((mod) => mod.Footer), {
  ssr: false
})

import { cn } from '@/lib/utils'

interface SiteLayoutProps {
  children: React.ReactNode
  className?: string
  serviceAble?: boolean
  searchAble?: boolean
  whatsappAble?: boolean
  language?: 'id' | 'en'
}

export const SiteLayout = ({
  children,
  className,
  serviceAble = false,
  searchAble = true,
  whatsappAble = true,
  language = 'id'
}: SiteLayoutProps) => (
  <Fragment>
    <Navbar serviceAble={serviceAble} searchAble={searchAble} language={language} />
    {whatsappAble && <WhatsappSticky language={language} />}
    <div className={cn('mt-[70px] lg:mt-24', className)}>{children}</div>
    <Footer language={language} />
  </Fragment>
)
