'use client'

import { useHydration } from '@/hooks/use-hydration'
import dynamic from 'next/dynamic'

import Container from '@/components/templates/container/container'
const OurServiceCarousel = dynamic(() =>
  import('@/components/molecules/carousel/our-service-carousel').then((mod) => mod.OurServiceCarousel)
)

interface LandingOurServiceProps {
  data: Service
}

export const LandingOurService = ({ data }: LandingOurServiceProps) => {
  const isHydrated = useHydration()

  if (!isHydrated) return null

  return (
    <section id="layanan-dan-harga" className="bg-white py-9 lg:py-16">
      <Container className="space-y-10">
        <header className="space-y-4 lg:space-y-6 text-center max-w-[700px] mx-auto">
          <h2 className="text-2xl font-extrabold lg:text-4xl text-primary-blue">Layanan Kami</h2>
          <p className="text-sm lg:text-base">Temukan solusi legalitas perusahaan Anda lewat berbagai layanan kami.</p>
        </header>

        <OurServiceCarousel data={data} />
      </Container>
    </section>
  )
}
