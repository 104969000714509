/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import { useHydration } from '@/hooks/use-hydration'
import dynamic from 'next/dynamic'
import Link from 'next/link'

const ArticleCarousel = dynamic(() =>
  import('@/components/molecules/carousel/article-carousel').then((mod) => mod.ArticleCarousel)
)
import Container from '@/components/templates/container/container'

interface ArticleListProps {
  title?: string
  language?: 'id' | 'en'
  data: Article
}

export const ArticleList = ({ title = 'Artikel Terbaru', language = 'id', data }: ArticleListProps) => {
  const isHydrated = useHydration()

  if (isHydrated == false || data.items.length === 0 || data.items === undefined) {
    return null
  }

  return (
    <section className="bg-white py-9 lg:py-16">
      <Container className="space-y-6 lg:space-y-12">
        <header className="flex items-center justify-between gap-4 text-primary-blue">
          <h2 className="text-2xl font-extrabold lg:text-4xl">{title}</h2>
          <Link href="/artikel" className="text-sm font-medium hover:underline">
            {language === 'id' ? 'Lihat Semua Artikel' : 'See All Articles'}
          </Link>
        </header>

        <ArticleCarousel data={data} />
      </Container>
    </section>
  )
}
