'use client'

const TestimonialCarousel = dynamic(() =>
  import('@/components/molecules/carousel/testimonial-carousel').then((mod) => mod.TestimonialCarousel)
)
import { useHydration } from '@/hooks/use-hydration'
import dynamic from 'next/dynamic'

import Container from '@/components/templates/container/container'

interface LandingTestimonialProps {
  data: Testimonial
}
export const LandingTestimonial = ({ data }: LandingTestimonialProps) => {
  const isHydrated = useHydration()

  if (!isHydrated || !data.items[0].fields.testimonial.length) {
    return null
  }

  return (
    <section className="bg-white py-9 lg:py-16">
      <Container className="space-y-10 max-w-[983px] h-full">
        <header className="space-y-4 lg:space-y-6 text-center max-w-[784px] mx-auto">
          <h2 className="text-2xl font-extrabold lg:text-4xl text-primary-blue">
            {data.items[0].fields.testimonialText.title}
          </h2>
          <p className="text-sm lg:text-base">{data.items[0].fields.testimonialText.description}</p>
        </header>

        <TestimonialCarousel data={data} />
      </Container>
    </section>
  )
}
