'use client'

import { useHydration } from '@/hooks/use-hydration'
import dynamic from 'next/dynamic'

const OurClientCarousel = dynamic(() =>
  import('@/components/molecules/carousel/our-client-carousel').then((mod) => mod.OurClientCarousel)
)

import Container from '@/components/templates/container/container'

interface Props {
  data: Klien
}

export const LandingOurClient = ({ data }: Props) => {
  const isHydrated = useHydration()

  if (!isHydrated || data.items[0] == undefined) return null

  return (
    <section id="our-client" className="bg-white py-9 lg:py-16">
      <Container className="max-w-6xl space-y-8 lg:space-y-10">
        <header className="text-center">
          <h2 className="text-xl lg:text-2xl font-bold text-[#0B1724]">
            {data?.items[0]?.fields.title || 'Lebih dari 3.000 Perusahaan Telah Percaya dengan Easybiz'}
          </h2>
        </header>

        <OurClientCarousel data={data} />
      </Container>
    </section>
  )
}
